<template>
  <div class="mt-8">
    <!--<h5 class="mb-4 mt-4">{{$t('PAGES.IMPORT.TAB_INFO_HEADER')}}</h5>-->

    <b-form ref="form" @submit.prevent="on_submit">

      <b-row>
        <b-col md="6" lg="6" sm="12">
          <b-form-group id="group-id" label="ID" label-for="input-id">
            <b-form-input
              id="field-id"
              v-model="import_record.import_id"
              type="text"
              disabled>
            </b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="6" lg="6" sm="12">
         <b-form-group id="group-created_at">
            <tip-label class="mb-2" for="field-name" :tip="$t('PAGES.IMPORT.CREATED_AT')" asterix>{{$t('PAGES.IMPORT.CREATED_AT')}}</tip-label>

            <b-form-input
              id="field-name"
              v-model="import_record.created_at"
              type="text"
              disabled>
            </b-form-input>

          </b-form-group>
        </b-col>

      </b-row>

      <b-row>
        <b-col md="6" lg="6" sm="12">
          <b-form-group id="group-filename" :label="$t('PAGES.IMPORT.FILENAME')" label-for="input-filename">
            <b-form-input
              id="field-filename"
              v-model="import_record.file.name"
              type="text"
              disabled>
            </b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="6" lg="6" sm="12">
         <b-form-group id="group-mappings">
            <tip-label class="mb-2" for="field-mappings" :tip="$t('PAGES.IMPORT.COUNT_MAPPINGS')" asterix>{{$t('PAGES.IMPORT.COUNT_MAPPINGS')}}</tip-label>

            <b-form-input
              id="field-name"
              v-model="import_record.created_at"
              type="text"
              disabled>
            </b-form-input>

          </b-form-group>
        </b-col>

      </b-row>

      <b-row>
        <b-col md="6" lg="6" sm="12">
          <b-form-group

            id="input-group-period-id"
            :label="$t('PAGES.IMPORT.TO_PERIOD')"
            label-for="input-period-id"
          >
            <b-form-select
              v-model="import_record.period_id" :options="period_options" disabled>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="6" lg="6" sm="12">
          <b-form-group

            id="input-group-created_by"
            :label="$t('PAGES.IMPORT.CREATED_BY')"
            label-for="input-created_by"
          >
            <b-form-input
              id="field-name"
              v-model="created_by"
              type="text"
              disabled>
            </b-form-input>
          </b-form-group>
        </b-col>


      </b-row>


      <b-row v-if="!disabled">
        <b-col>

          <!--<b-button @click.prevent="on_map_columns_continue" v-if="next_step === 'map_columns'" ref="save-map-columns" class="mt-8 save" variant="primary">{{$t('PAGES.IMPORT.BUTTON_MAP_COLUMNS')}}</b-button>-->
          <!--<b-button @click.prevent="on_validation_continue" v-if="next_step === 'validation'" ref="save-validate" class="mt-8 save" variant="primary">{{$t('PAGES.IMPORT.BUTTON_VALIDATE')}}</b-button>-->
          <!--<b-button @click.prevent="on_finalize_continue" v-if="next_step === 'finalize'" ref="save-finalize" class="mt-8 save" variant="primary">{{$t('PAGES.IMPORT.BUTTON_FINALIZE')}}</b-button>-->

        </b-col>
      </b-row>

    </b-form>


  </div>

</template>

<style lang="scss" scoped>
</style>

<script>
import { mapGetters } from 'vuex';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import ColorPickerInput from '@/view/components/ColorPickerInput';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {

  name: 'import-edit-tab-info',

  components: { TipLabel, RequiredFieldsInfo, ColorPickerInput },

  props: ['import_record','disabled','next_step'],
  emits: ['on_finalize_continue', 'on_validation_continue', 'on_map_columns_continue'],
  mixins: [ toasts ],

  computed: {
    ...mapGetters(['currentCompanyId', 'periods']),
    period_options() {
      return this.periods.map(item => ({ value: item.id, text: item.name }));
    },
    created_by() {
      if (this.import_record.user) {

        return this.if_null_else(this.import_record.user.firstname, this.import_record.user.email, this.import_record.user.firstname + ' ' + this.import_record.user.lastname);
      }

      return 'Ej satt';
    }
  },

  watch: {

  },

  methods: {

    on_map_columns_continue() {
      this.$emit('on_finalize_continue');
    },
    on_validation_continue() {
      this.$emit('on_validation_continue');
    },
    on_finalize_continue() {
      this.$emit('on_finalize_continue');
    },

    if_null_else(to_check, if_false, if_true) {
      if (to_check === null || to_check === undefined || (to_check+'').length === 0) {
        return if_false;
      }

      return if_true;
    }
  },

  mounted() {
  },

  data() {
    return {

    };
  }

};

</script>
