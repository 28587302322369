<template>
  <b-modal ref="fullscreen-popup" hide-footer hide-header>
    <div class="row">
      <div class="col-12 text-center">
        <img style="max-width: 200px;" alt="ripples" src="/assets/images/spinner_loader.gif" />
      </div>
    </div>
    <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
      <div class="col-12 text-center">
        <h4 class="event-name" style="text-align: center;">{{ header }}</h4>
        <br />
        <p>
          {{ text }}
        </p>

      </div>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'FullscreenLoader',
  data() {
    return {
      
    };
  },
  props: {
    text: String,
  },
  async mounted() {
    
  },
  watch: {
    
  },
  methods: {
    show() {
      this.$refs['fullscreen-popup'].show();
    },
    hide() {
      this.$refs['fullscreen-popup'].hide();
    },
  },
  computed: {
    
  }
};
</script>
