<template>

  <div class="mt-8">

    <!--<h5 class="mb-4 mt-4">{{$t('PAGES.IMPORT.TAB_VALIDATION_HEADER')}}</h5>-->


    <div v-if="running_validation">

      <div class="row">
        <div class="col-12 text-center">
          <b-spinner
            class="mx-auto text-center align-center"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <h3>
            {{$t('PAGES.IMPORT.RUNNING_VALIDATION')}}
          </h3>
        </div>
      </div>
    </div>

    <div v-else-if="done_validation">

      <div class="row">
        <div class="col-12 text-center">
          <i class="fa fa-check-circle" style="font-size: 42px; color: #8dda8d;"></i>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <h3>
            {{$t('PAGES.IMPORT.DONE_VALIDATION')}}
          </h3>
        </div>
      </div>

      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <button
            v-if="!disabled"
            class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
            @click.prevent="on_continue_to_finalize_clicked"
          >
          {{$t('PAGES.IMPORT.CONTINUE')}}
          </button>

        </div>
      </div>

    </div>

    <div v-else-if="fail_validation">
      <div class="row">
        <div class="col-12 text-center">
          <i class="fa fa-times-circle" style="font-size: 42px; color: #de1212;"></i>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <h3>
            {{$t('PAGES.IMPORT.FAILURE_VALIDATION')}}
          </h3>
        </div>
      </div>

      <ImportValidationErrorReport
        :error_report="error_report"
      />
    </div>

    <div v-else-if="wait_validation">
      <div class="row">
        <div class="col-12 text-center">
          <h3>
            {{$t('PAGES.IMPORT.READY_TO_VALIDATE')}}
          </h3>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <button
            class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
            v-if="!disabled"
            @click.prevent="on_continue_clicked"
            :disabled="continue_button_disabled"
          >
          {{$t('PAGES.IMPORT.CONTINUE')}}
          </button>

        </div>
      </div>
    </div>

  </div>


</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import ImportValidationErrorReport from '../ImportValidationErrorReport.vue';

export default {
  name: 'import-validation-error-report-page',
  props: ['error_report','disabled','import_record'],
  emits: ['on_continue', 'on_continue_to_finalize'],
  components: {
    ImportValidationErrorReport
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),

    continue_button_disabled() {
      return !this.wait_validation;
    },

    running_validation() {
      switch (this.import_record.status) {
        case 'RUN_VALIDATION': {
          return true;
        }
      }

      return false;
    },

    done_validation() {
      switch (this.import_record.status) {

        case 'DONE_VALIDATION':
        case 'WAIT_FINALIZE':
        case 'RUN_FINALIZE':
        case 'DONE_FINALIZE': {
          return true;
        }
      }

      return false;
    },

    fail_validation() {
      switch (this.import_record.status) {
        case 'FAIL_VALIDATION': {
          return true;
        }
      }

      return false;
    },

    wait_validation() {
      switch (this.import_record.status) {
        case 'DONE_MAP_COLUMNS':
        case 'WAIT_VALIDATION': {
          return true;
        }
      }

      return false;
    }


  },
  data() {
    return {
      headers: [
        {
          key: 'row',
          show: true,
          sortable: true,
          label: this.$t('PAGES.IMPORT.ROW'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'cell_header',
          show: true,
          sortable: false,
          label: this.$t('PAGES.IMPORT.CELL_HEADER'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'message',
          show: true,
          sortable: false,
          label: this.$t('PAGES.IMPORT.MESSAGE'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center',
        }
      ]
    };
  },
  mounted() {},
  watch: {

  },
  methods: {

    on_continue_to_finalize_clicked() {
      this.$emit('on_continue_to_finalize');
    },

    on_continue_clicked() {
      this.$emit('on_continue');
    }

  }
};
</script>
