<template>

  <div class="card card-custom card-stretch gutter-b">

    <div class="card-body pt-0 pb-3">
      <b-alert show variant="danger">Fel uppstod vid validering av import {{ import_id }}</b-alert>

      <div>
        
        <b-table
          id="error_report-table"
          class="mh-100"
          :fields="headers"
          :items="error_report"
          head-variant="light"
          sticky-header
          hover
          sort-icon-left
        >
          
        </b-table>

        
      </div>
  
    </div>
  
  </div>
  
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'import-validation-error-report-page',
  props: ['error_report'],
  mixins: [ toasts ],
  components: {
    
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  data() {
    return {
      headers: [
        {
          key: 'row',
          show: true,
          sortable: true,
          label: this.$t('PAGES.IMPORT.ROW'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'cell_header',
          show: true,
          sortable: false,
          label: this.$t('PAGES.IMPORT.CELL_HEADER'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'message',
          show: true,
          sortable: false,
          label: this.$t('PAGES.IMPORT.MESSAGE'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center',
        }
      ]
    };
  },
  mounted() {},
  watch: {
    
  },
  methods: {
    
  }
};
</script>
