<template>
  <div class="mt-8">

    <div v-if="running_finalize">

      <div class="row">
        <div class="col-12 text-center">
          <b-spinner
            class="mx-auto text-center align-center"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <h3>
            {{$t('PAGES.IMPORT.RUNNING_FINALIZE')}}
          </h3>
        </div>
      </div>
    </div>

    <div v-else-if="done_finalize">

      <div class="row">
        <div class="col-12 text-center">
          <i class="fa fa-check-circle" style="font-size: 42px; color: #8dda8d;"></i>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <h3>
            {{$t('PAGES.IMPORT.SUCCESS_FINALIZE')}}
          </h3>
        </div>
      </div>

    </div>

    <div v-else-if="fail_finalize">
      <div class="row">
        <div class="col-12 text-center">
          <i class="fa fa-times-circle" style="font-size: 42px; color: #de1212;"></i>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <h3>
            {{$t('PAGES.IMPORT.FAILURE_FINALIZE')}}
          </h3>
        </div>
      </div>
    </div>

    <div v-else-if="wait_finalize">
      <div class="row">
        <div class="col-12 text-center">
          <h3>
            {{$t('PAGES.IMPORT.READY_TO_FINALIZE')}}
          </h3>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">

          <button
            class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
            @click.prevent="on_click_finalize"
            :disabled="disabled"
          >
          {{$t('PAGES.IMPORT.FINALIZE_IMPORT')}}
          </button>

        </div>
      </div>
    </div>

  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'import-edit-tab-finalize',
  mixins: [ toasts ],
  props: ['import_record','disabled'],
  emits: ['on_finalize_start'],
  components: {

  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'isTHS']),

    running_finalize() {
      switch (this.import_record.status) {
        case 'RUN_FAST_FINAL_IMPORT':
        case 'RUN_FINALIZE':
        case 'RUN_FIN_IMPORT': {
          return true;
        }
      }

      return false;
    },

    done_finalize() {
      switch (this.import_record.status) {
        case 'DONE_FINALIZE':
        case 'DONE_FAST_FINAL_IMPORT':
        case 'DONE_FINAL': {
          return true;
        }
      }

      return false;
    },

    fail_finalize() {
      switch (this.import_record.status) {
        case 'FAIL_FAST_FINAL_IMPORT':
        case 'FAIL_FINALIZE':
        case 'FAIL_FINAL': {
          return true;
        }
      }

      return false;
    },

    wait_finalize() {
      switch (this.import_record.status) {
        case 'WAIT_FINALIZE':
        case 'DONE_FIRST':
        case 'DONE_VALIDATION':
        case 'VALIDATED_OK':
        case 'DONE_FAST_FIRST_IMPORT': {
          return true;
        }
      }

      return false;
    }
  },

  data() {
    return {

    };
  },
  watch: {

  },
  methods: {
    on_click_finalize() {
      this.$emit('on_finalize_start', this.import_record.import_id);
    },
  }
};
</script>
