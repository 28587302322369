<template>
  <div class="text-nowrap">
    <label class="my-0 py-0"><slot></slot></label>
    <label v-if="asterix" class="text-success ml-1 my-0"><strong>*</strong></label>
    <span v-if="tip" v-b-hover="on_icon_hover" :class="`svg-icon svg-icon-sm ml-4 my-0 text-${icon_color}`" v-b-tooltip="{ title: tip, placement: 'top', boundary: 'window', trigger: 'hover' }">
      <inline-svg src="/assets/svg/question-circle-fill.svg"></inline-svg>
    </span>
  </div>
</template>

<script>
export default {
  name: 'tip-label',
  props: {
    asterix: Boolean,
    tip: { type: String, required: false}
  },
  data() {
    return { icon_color: 'muted' }
  },
  methods: {
    on_icon_hover(hovered) { this.icon_color = hovered ? 'primary' : 'muted' }
  }
}
</script>

<style scoped>
.tooltip { font-size: 1.0em; }
.label {
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
