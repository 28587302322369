<template>
  <div class="color-picker">
    <input
      type="text"
      v-model="color"
      :placeholder="placeholder"
      class="form-control"
      style="padding-left: 35px;"
      disabled
    >
    <input
      type="color"
      v-model="color"
      class="color-picker-color"
      :style="backgroundStyle"
    >
  </div>
</template>

<script>
export default {
  name: "ColorPickerInput",
  props: {
    value: {
      type: String,
      default: '#000000'
    },
    placeholder: {
      type: String,
      default: '#000000'
    },
  },
  emits: ['change', 'input'],
  computed: {
    color: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
        this.$emit('input', val)
      }
    },
    backgroundStyle() {
      return { background: (this.color.length > 0 && this.color.length === 7) ? this.color : '#000000'};
    }
  }
}
</script>

<style lang="scss" scoped>
.color-picker {
  position: relative;

  input:not([type=color]) {
    text-transform: uppercase;
  }

  &-color {
    border-radius: 3px;
    padding: 0;
    border: 1px solid #AAA;
    position: absolute;
    left: 5px;
    width: 26px;
    height: 26px;
    top: calc(50% - 13px);

    &:focus {
      outline: none;
    }

    &::-webkit-color-swatch {
      border: none;
    }
  }
}

</style>
