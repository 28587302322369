<template>
  <tr>

    <td>
      <span class="font-weight-bolder d-block font-size-lg">
        {{ local_mapped_column?.excel?.col+1 || 0 }}
      </span>
    </td>
    <td>
      <span class="font-weight-bolder d-block font-size-lg">{{ local_mapped_column?.excel?.name || '' }}</span>
    </td>
    <td>
      <b-form-select
        v-model="local_mapped_column.db.col_name"
        :disabled="disabled"
        :options="db_column_options"
        :state="col_state"
        @change="select_changed"
      ></b-form-select>
    </td>
    <td>
      <b-badge v-if="status === 'SKIP'" variant="warning">!</b-badge>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ImportColDefRow',
  props: ['mapped_column', 'index', 'status', 'db_column_options', 'disabled'],
  emits: ['on_mapped_column_changed'],
  computed: {
    ...mapGetters(['currentCompanyId']),
    col_state() {
      return (this.status === 'OK' && !this.local_mapped_column.duplicate)
        || this.status === 'SKIP'
    },
  },
  data() {
    return {
      local_mapped_column: {
        db: {
          col_name: ''
        },
      },
      value: 0
    };
  },
  mounted() {
    if (this.mapped_column) {
      this.local_mapped_column = { ...this.mapped_column }
    }
  },
  watch: {
    mapped_column: {
      deep: true,
      handler(new_val) {
        this.local_mapped_column = { ...new_val }
      }
    },
    local_mapped_column: {
      deep: true,
      handler(new_val) {
        this.value = new_val.db.col_name;
      }
    }
  },
  methods: {
    select_changed() {
      this.$emit('on_mapped_column_changed', this.index, this.local_mapped_column.db.col_name);
    }
  }
};
</script>
