<template>
  <div class="mt-8">
    <!--<h5 class="mb-4 mt-4">{{$t('PAGES.IMPORT.TAB_MAP_COLUMNS_HEADER')}}</h5>-->

    <!--<ul>
      <li v-if="not_set_public_id" style="color: red;">Medlemsnummer måste väljas</li>
      <li v-if="not_set_cnum" style="color: red;">Föreningsnummer måste väljas</li>
      <li v-if="not_set_shop_item_id" style="color: red;">Medlemskap ButiksID måste väljas</li>
    </ul>-->

    <a v-if="!disabled" class="btn btn-secondary font-weight-bolder mb-8" @click.prevent="skip_all"
      >{{$t('PAGES.IMPORT.SKIP_ALL')}}</a
    >

    <div class="list-selects">
      <div class="table-responsive">
        <table
          class="table table-head-custom table-vertical-center table-head-bg table-borderless"
        >
          <thead>
            <tr class="text-left">
              <th style="min-width: 40px">
                <span class="text-dark-75">{{ $t('PAGES.IMPORT.COLUMN') }}</span>
              </th>
              <th>{{ $t('PAGES.IMPORT.EXCEL_FIELD') }}</th>
              <th>{{ $t('PAGES.IMPORT.MEMLIST_FIELD') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody ref="colDefRows" v-if="column_statuses && local_mappings && column_statuses.length > 0 && local_mappings.length > 0">

            <ImportColDefRow
              v-for="(item, index) in local_mappings"
              ref="importColDefRows"
              v-bind:key="index"
              :disabled="disabled"
              :status="column_statuses[index]"
              :index="index"
              :mapped_column="item"
              :currentCompanyId="currentCompanyId"
              :db_column_options="sorted_column_options"
              @on_mapped_column_changed="on_mapped_column_changed"
            />
          </tbody>
          <tfoot v-if="!disabled">
            <tr>
              <td></td>
              <td></td>
              <td>
                <div class="justify-content-end d-flex">
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    @click.prevent="on_continue_clicked"
                    :disabled="continue_button_disabled"
                  >
                  {{$t('PAGES.IMPORT.CONTINUE')}}
                  </button>
                </div>

              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import ImportColDefRow from '@/view/pages/ml/import/edit_tabs/map_columns/ImportColDefRow.vue';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

export default {
  name: 'ImportEditTabMapColumns',
  props: [
    'import_record',
    'import_id',
    'variant', /// 'MEMBER' or 'COMPANY'
    'mappings', /// either comes from the old import record or the excel file read
    'type_def', /// the memlist database structure, use this to iterate and make a selection box
    'disabled'
  ],
  emits: ['on_continue', 'on_validation', 'on_mapped_column_changed', 'on_skip_all'],
  mixins: [ toasts ],
  components: {
    ImportColDefRow
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),

    continue_button_disabled() {
      return this.duplicates;
    },

    sorted_column_options() {
      return this.db_column_options.sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      });
    },
  },
  data() {
    return {
      duplicates: false,

      column_statuses: [],

      db_column_options: [],

      local_mappings: [],

      orginalColumnDefs: [],
      not_set_public_id: true,
      not_set_cnum: true,
      not_set_shop_item_id: true,

      requirements: {
        personnr: false,
        ssn: false,
        phone: false,
        email: false,
        address: false,
        fullname: false,
        emailorphone: false,
        sex: false,
        age: false
      },
    };
  },
  mounted() {
    if (this.mappings) {
      this.local_mappings = [...this.mappings];
    }
    if (this.type_def && this.local_mappings) {
      this.set_db_col_def_options(this.type_def, this.local_mappings);
    }

  },
  watch: {
    'import_record.import_id': {
      deep: true, // check properties of the form, not just the form reference (value.name for example)
      handler(val) {

      }
    },

    import_id(newValue, oldValue) {
      if (newValue) {
        this.set_db_col_def_options(this.type_def, this.local_mappings);
      }
    },
    type_def(newValue, oldValue) {
      this.set_db_col_def_options(this.type_def, this.local_mappings);
    },
    local_mappings: {
      deep: true,
      handler() {
        this.calculate_duplicates();
      }
    },
  },
  methods: {
    on_continue_clicked() {
      this.$emit('on_continue');
    },

    on_mapped_column_changed(index, col_name) {
      this.$emit('on_mapped_column_changed', index, col_name);
    },

    skip_all() {
      for (let i = 0; i < this.local_mappings.length; ++i) {
        this.$emit('on_skip_all');
      }
    },

    calculate_duplicates() {
      this.not_set_public_id = true;
      this.not_set_cnum = true;
      this.not_set_shop_item_id = true;

      this.duplicates = false;

      // we set duplicate rows as red
      for (let i = 0; i < this.local_mappings.length; ++i) {

        const mc1 = this.local_mappings[i];
        mc1.duplicate = false;

        if (mc1.db.col_name === 'skip') {
          this.column_statuses[i] = 'SKIP';
          // Vue3 compatability for Vue.set
          this.column_statuses = [...this.column_statuses];
        }
        else {
          this.column_statuses[i] = 'OK';
          // Vue3 compatability for Vue.set
          this.column_statuses = [...this.column_statuses];
        }

        switch (mc1.db.col_name) {
          case 'cnum': {
            this.not_set_cnum = false;
            break;
          }
          case 'public_id': {
            this.not_set_public_id = false;
            break;
          }
          case 'shop_item_id': {
            this.not_set_shop_item_id = false;
            break;
          }
          default: {
            break;
          }
        }
      }

      for (let i = 0; i < this.local_mappings.length; ++i) {
        const mc1 = this.local_mappings[i];

        if (mc1.db.col_name === 'skip') {
          continue;
        }

        for (let j = 0; j < this.local_mappings.length; ++j) {
          if (i === j) {
            continue;
          }

          const mc2 = this.local_mappings[j];

          if (mc1.db.col_name === mc2.db.col_name) {
            mc1.duplicate = true;
            this.duplicates = true;

            this.column_statuses[i] = 'DUPLICATE';
            // Vue3 compatability for Vue.set
            this.column_statuses = [...this.column_statuses];
          }
          else {
            mc1.duplicate = false;
          }
        }
      }

      /// on_validation === true means its ok to proceed
      this.$emit('on_validation', !this.duplicates);

    },

    /// populates the select options inside the db_column_options array
    set_db_col_def_options(type_def, mappings) {
      this.db_column_options = []; /// these are the selection options in the select box

      this.column_statuses = [];

      for (const c of mappings) {
        this.column_statuses.push('OK');
      }

      /// this.type_def.columns is columns within memlist database

      for (const k in type_def.columns) {
        const col = type_def.columns[k];

        if (col.func === 'attr') {
          this.db_column_options.push({
            value: col.col_name,
            text: '* ' + col.lang_record.text.substring(0,1).toUpperCase() + col.lang_record.text.substring(1),
            col: col
          });
        }
        else {
          this.db_column_options.push({
            value: col.col_name,
            text: this.variant === 'MEMBER' ? this.$t('MEMBER.' + col.col_name.toUpperCase()) : this.$t('COMPANY.' + col.col_name.toUpperCase()),
            col: col
          });
        }
      }

      this.db_column_options.sort((a,b) => {
        if (a.text < b.text) {
          return -1;
        }

        return 1;
      });

      this.calculate_duplicates();
    },
  }
};
</script>
