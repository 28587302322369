<template>
  <div>

    <b-card
      title=""
      class="mb-2"
      border-variant="secondary"
      hide-footer>

      <v-tabs
        v-model="activetab_href_variable"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
        background-color="white"
        color="accent-4"
        left>

        <v-tab :disabled="options_disabled">{{ $t('PAGES.IMPORT.OPTIONS') }}</v-tab>
        <v-tab :disabled="map_columns_disabled">{{ $t('PAGES.IMPORT.MAP_COLUMNS') }}</v-tab>
        <v-tab :disabled="validation_disabled">{{ $t('PAGES.IMPORT.VALIDATION') }}</v-tab>
        <v-tab :disabled="finalize_disabled">{{ $t('PAGES.IMPORT.FINALIZE') }}</v-tab>

        <v-tab-item>

          <ImportEditTabInfo
            :next_step="next_step"
            :disabled="disabled"
            :import_record="import_record"
            @on_finalize_continue="on_finalize_continue"
            @on_validation_continue="on_validation_continue"
            @on_map_columns_continue="on_map_columns_continue"

          />
        </v-tab-item>

        <v-tab-item>

          <ImportEditTabMapColumns
            :variant="variant"
            :import_id="import_record.import_id"
            :disabled="disabled"
            :mappings="import_record.mappings"
            :type_def="type_def"
            :import_record="import_record"
            @on_skip_all="on_skip_all_columns"
            @on_mapped_column_changed="on_mapped_column_changed"
            @on_continue="on_mapping_continue"
          />
        </v-tab-item>

        <v-tab-item>
          <ImportEditTabValidation
            :disabled="disabled"
            :error_report="import_record.error_report"
            :import_record="import_record"
            @on_start="on_validation_start"
            @on_continue="on_validation_continue"
            @on_continue_to_finalize="on_continue_to_finalize"
          />
        </v-tab-item>

        <v-tab-item>
          <ImportEditTabFinalize
            :disabled="disabled"
            :import_record="import_record"
            @on_finalize_start="on_finalize_start"
          />
        </v-tab-item>

      </v-tabs>
    </b-card>


  </div>

</template>

<script>
import ImportEditTabInfo from '@/view/pages/ml/import/edit_tabs/ImportEditTabInfo.vue';
import ImportEditTabMapColumns from '@/view/pages/ml/import/edit_tabs/ImportEditTabMapColumns.vue';
import ImportEditTabValidation from '@/view/pages/ml/import/edit_tabs/ImportEditTabValidation.vue';
import ImportEditTabFinalize from '@/view/pages/ml/import/edit_tabs/ImportEditTabFinalize.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'import-editor',
  props: ['variant', 'import_record', 'disabled'], /// 'MEMBER' or 'COMPANY'
  emits: ['on_map_columns_done', 'on_validation_continue', 'on_finalize_start', 'on_skip_all_columns', 'on_mapped_column_changed'],
  mixins: [ toasts ],
  components: {
    ImportEditTabInfo,
    ImportEditTabMapColumns,
    ImportEditTabValidation,
    ImportEditTabFinalize
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    next_step() {
      switch (this.import_record.status) {
        case 'WAIT_FILE_UPLOAD': {
          return 'file_upload';
        }
        case 'WAIT_MAP_COLUMNS':
        case 'DONE_FILE_UPLOADED': {
          return 'map_columns';
        }
        case 'WAIT_FINALIZE':
        case 'DONE_VALIDATION': {
          return 'finalize';
        }
        case 'WAIT_VALIDATION': {
          return 'validation';
        }
      }

      return 'none';
    },
    options_disabled() { return false; }, /// options are never disabled
    map_columns_disabled() { return false; }, /// map columns are never disabled
    validation_disabled() {
      switch (this.import_record.status) {
        case 'DONE_MAP_COLUMNS':
        case 'WAIT_VALIDATION':
        case 'RUN_VALIDATION':
        case 'DONE_VALIDATION':
        case 'FAIL_VALIDATION':
        case 'DONE_FINALIZE':
        case 'WAIT_FINALIZE':
        case 'RUN_FINALIZE':
        case 'FAIL_FINALIZE': {
          return false;
        }
      }

      return true;
    },
    finalize_disabled() {
      switch (this.import_record.status) {
        case 'DONE_VALIDATION':
        case 'DONE_FINALIZE':
        case 'WAIT_FINALIZE':
        case 'RUN_FINALIZE':
        case 'FAIL_FINALIZE': {
          return false;
        }
      }

      return true;
    },

  },
  data() {
    return {

      activetab_href_variable: 0,

      /// type_def must be fetched initially and stored away
      type_def: null,

    };
  },
  async mounted() {
    this.type_def = await this.get_type_def(this.variant, this.currentCompanyId);
  },
  watch: {

  },
  methods: {

    on_mapping_continue() {

      this.$emit('on_map_columns_done', this.import_record.import_id);

      /// proceed from map columns to validation
      this.set_active_tab('validation');
    },

    set_active_tab(name) {

      this.$nextTick(()=>{

        switch (name) {
          case 'options':
            this.activetab_href_variable = 0;
            break;
          case 'map_columns':
            this.activetab_href_variable = 1;
            break;
          case 'validation':
            this.activetab_href_variable = 2;
            break;
          case 'finalize':
            this.activetab_href_variable = 3;
            break;
        }

      });
    },

    on_continue_to_finalize() {
      this.set_active_tab('finalize');
    },

    /// when validation should start
    async on_validation_start() {},

    /// when wanting to move away from the validation screen and continue
    async on_validation_continue() {
      this.$emit('on_validation_continue');
    },


    /// when finalize should start
    async on_finalize_start(import_id) {
      this.$emit('on_finalize_start', import_id);
    },

    /// when the mapping has been validated
    /// so we should update the status of the import
    async on_mappings_validation(valid) {

    },

    /// when wanting to continue from the mapping screen
    /// should forward to the validation screen
    async on_mappings_continue() {

    },

    async on_skip_all_columns() {
      this.$emit('on_skip_all_columns');
    },

    async on_mapped_column_changed(index, col_name) {
      this.$emit('on_mapped_column_changed', index, col_name);
    },

    /// the memlist table definitions
    async get_type_def(variant, company_id) {

      if (variant !== 'COMPANY' && variant !== 'MEMBER') {
        console.error('invalid variant ' + variant);
        return false;
      }

      try {
        const result = await axios.get(`/import/type_def/${variant.toLowerCase()}/${company_id}`);

        if (result.status === 200) {
          return result.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.NOT_FOUND'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.ERROR'));
      }
    },


  }
};
</script>
