<template>
  <div>
    <b-row class="justify-content-end my-4 mx-1">
      <b-button variant="primary" @click.prevent="new_clicked()">(+) {{$t('PAGES.IMPORT.NEW_IMPORT')}}</b-button>
    </b-row>

    <FullscreenLoader
      ref="fullscreen-loader-ai"
      :text="$t('PAGES.IMPORT.WAIT_WHILE_AI_MAPPING')"
    />
    
    <ErrorPopup 
      :error="error_message"
    />

    <ImportTable
      :items="active_imports"
      @on_import_selected="on_import_selected"
      @on_import_deleted="on_import_deleted"
    />

    <ImportNewImportModal
      ref="new-import-modal"
      @on_continue="on_new_import_continue"
    />

    <ImportNewCompanyImportModal
      ref="new-import-company-modal"
      @on_continue="on_new_company_import_continue"
    />

    <ImportEditor
      v-if="import_record"
      ref="import-editor"

      @on_mapped_column_changed="on_mapped_column_changed"
      @on_skip_all_columns="on_skip_all_columns"
      @on_map_columns_done="on_map_columns_done"

      @on_validation_continue="on_validation_continue"
      @on_finalize_start="on_finalize_start"

      :import_record="import_record"
      :variant="variant"
    />
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import ImportTable from '@/view/pages/ml/import/components/ImportTable.vue';
import ImportEditor from '@/view/pages/ml/import/ImportEditor.vue';
import ImportNewImportModal from '@/view/pages/ml/import/components/ImportNewImportModal.vue';
import ImportNewCompanyImportModal from '@/view/pages/ml/import/components/ImportNewCompanyImportModal.vue';

import ErrorPopup from '@/view/components/ErrorPopup.vue';

import FullscreenLoader from '@/view/components/FullscreenLoader.vue';



export default {
  name: 'import-page',
  mixins: [ toasts ],
  components: {
    ImportTable,
    ImportNewImportModal,
    ImportNewCompanyImportModal,
    ImportEditor,
    ErrorPopup,
    FullscreenLoader
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'isTHS'])
  },

  props: ['variant'], /* variant = MEMBER, COMPANY */

  data() {
    return {
      error_message: null,
      import_record: null,
      active_imports: [],
      poll_interval_id: null
    };
  },
  watch: {

  },

  async mounted() {
    this.active_imports = await this.get_active_imports();
  },

  methods: {

    async on_finalize_start(import_id) {
      try {

        const updated_import = await this.post_start_finalize(this.import_record);

        if (updated_import === null) {
          console.error('unable to post start finalize');
          return;
        }

        this.import_record.status = updated_import.status;
        // Vue3 compatability for Vue.set
        this.import_record = { ...this.import_record }

        this.start_poll_import();

      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.UNABLE_UPDATE'));
      }
    },

    async on_validation_continue() {
      try {

        const updated_import = await this.post_start_validate(this.import_record);

        if (updated_import === null) {
          console.error('unable to post start validate');
          return;
        }

        this.import_record.status = updated_import.status;
        // Vue3 compatability for Vue.set
        this.import_record = { ...this.import_record }

        this.start_poll_import();

      }
        catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.UNABLE_UPDATE'));
      }
    },

    async post_start_finalize(record) {
      try {
        const result = await axios.post(`/import/finalize/${record.import_id}`, {});

        if (result.status === 200) {
          return result.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.NOT_FOUND'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.ERROR'));
      }

      return null;
    },

    async post_start_validate(record) {
      try {
        const result = await axios.post(`/import/validate/${record.import_id}`, {});

        if (result.status === 200) {
          return result.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.NOT_FOUND'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.ERROR'));
      }

      return null;
    },

    async on_skip_all_columns() {

      try {
        for (let i = 0; i < this.import_record.mappings.length; ++i) {
          this.import_record.mappings[i].db.col_name = 'skip';
          this.import_record.mappings[i] = this.import_record.mappings[i];
          // Vue3 compatability for Vue.set
          this.import_record = [...this.import_record];
        }

        const updated_import = await this.put_import(this.import_record);

        this.import_record = updated_import;
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.UNABLE_UPDATE'));
      }

    },

    async on_map_columns_done(import_id) {
      try {

        this.import_record.status = 'DONE_MAP_COLUMNS';

        const updated_import = await this.put_import(this.import_record);

        this.update_import_in_active_list(updated_import);

        this.import_record.status = updated_import.status;
        // Vue3 compatability for Vue.set
        this.import_record = { ...this.import_record }

        this.import_record.status = updated_import.status;
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.UNABLE_UPDATE'));
      }
    },

    async on_mapped_column_changed(index, col_name) {
      try {

        this.import_record.mappings[index].db.col_name = col_name;

        const updated_import = await this.put_import(this.import_record);

        this.update_import_in_active_list(updated_import);

        this.import_record.mappings[index] = updated_import.mappings[index];
        this.import_record.status = updated_import.status;
        // Vue3 compatability for Vue.set
        this.import_record = { ...this.import_record }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.UNABLE_UPDATE'));
      }
    },

    new_clicked() {
      if (this.variant === 'MEMBER') {
        this.$refs['new-import-modal'].show();
      }
      else if (this.variant === 'COMPANY'){
        this.$refs['new-import-company-modal'].show();
      }
      else {
        throw `invalid variant ${this.variant}`
      }

      this.import_record = null;
    },

    async on_import_selected(import_id) {
      try {
        this.import_record = await this.get_import(import_id);

        const tab = this.tab_for_status(this.import_record.status);

        this.$nextTick(() => {
          this.$refs['import-editor'].set_active_tab(tab);

          this.stop_poll_import();
          this.start_poll_import();
        });
      }
      catch (err) {

      }
    },

    stop_poll_import() {
      if (this.poll_interval_id) {
        clearInterval(this.poll_interval_id);
        this.poll_interval_id = null;
      }
    },

    start_poll_import() {

      if (this.poll_interval_id) {
        return;
      }

      const that = this;

      this.poll_interval_id = setInterval(() => {

        that.poll_import();

      }, 5000);
    },

    async poll_import() {
      try {

        if (this.import_record === null) {
          this.stop_poll_import();
          return;
        }

        const record = await this.get_import(this.import_record.import_id);

        this.import_record = {
          ...this.import_record,
          status: record.status,
          error_report: record.error_report,
          num_records_error: record.num_records_error,
          num_records_inserted: record.num_records_inserted,
          num_records_updated: record.num_records_updated,
          mappings: record.mappings
        }


        this.update_import_in_active_list(record);

        if (record.status === 'DONE_FINALIZE') {
          /// no need to poll finished import
          this.stop_poll_import();
        }
      }
      catch (err) {
        console.error('poll error', err);
      }
    },

    update_import_in_active_list(import_record) {
      const index = this.active_imports.findIndex(item => item.import_id === import_record.import_id);

      if (index === -1) {
        return;
      }

      this.active_imports[index] = import_record;
      // Vue3 compatability for Vue.set
      this.active_imports = [...this.active_imports];
    },

    tab_for_status(status) {

      switch (status) {
        case 'WAIT_FILE_UPLOAD': {
          return 'options';
        }
        case 'WAIT_MAP_COLUMNS':
        case 'DONE_FILE_UPLOADED': {
          return 'map_columns';
        }
        case 'WAIT_FINALIZE':
        case 'RUN_FINALIZE':
        case 'DONE_VALIDATION': {
          return 'finalize';
        }
        case 'DONE_MAP_COLUMNS':
        case 'RUN_VALIDATION':
        case 'WAIT_VALIDATION': {
          return 'validation';
        }
      }

      return 'options';
    },

    async on_import_deleted(import_id) {
      try {
        const result = await this.delete_import(import_id);

        if (result) {
          if (this.import_record && this.import_record.import_id === import_id) {
            this.import_record = null;
          }

          this.active_imports = this.active_imports.filter(item => item.import_id !== import_id);
        }

      }
      catch (err) {

      }
    },

    /// User selected a period and uploaded a file
    /// Now we need to create a new import
    async on_new_company_import_continue(primary_key, file_id) {

      this.$refs['new-import-company-modal'].hide();

      const record = {
        period_id: this.currentPeriodId,
        file_id: file_id,
        company_id: this.currentCompanyId,
        mappings: [],
        version: 'FAST_V1',
        primary_key: primary_key
      }

      const result = await this.post_import(record, 'COMPANY');

      if (result.import_id) {

        this.active_imports.push(result);

        this.import_record = result;

        /// proceed to the import editor

        this.$nextTick(() => {
          this.$refs['import-editor'].set_active_tab('map_columns');
        });
      }
    },


    /// User selected a period and uploaded a file
    /// Now we need to create a new import
    async on_new_import_continue(period_id, primary_key, file_id) {

      this.$refs['new-import-modal'].hide();

      const record = {
        period_id: period_id,
        file_id: file_id,
        company_id: this.currentCompanyId,
        mappings: [],
        version: 'FAST_V1',
        primary_key: primary_key
      }

      const result = await this.post_import(record, 'MEMBER');

      if (result.import_id) {

        this.active_imports.push(result);

        this.import_record = result;

        /// proceed to the import editor

        this.$nextTick(() => {
          this.$refs['import-editor'].set_active_tab('map_columns');
        });
      }
    },

    /**
     *
     * @param {*} record
     * @param {*} variant COMPANY, MEMBER
     */
    async post_import(record, variant) {

      if (variant !== 'COMPANY' && variant !== 'MEMBER') {
        throw `invalid variant ${variant}`
      }
      
      this.$refs['fullscreen-loader-ai'].show();


      try {
        const result = await axios.post(`/import/${variant.toLowerCase()}/job`, record);

        if (result.status === 201) {
          
          this.$refs['fullscreen-loader-ai'].hide();

          return result.data;
        }
        else if (result.status === 400) {

          this.$refs['fullscreen-loader-ai'].hide();

          if (result.data.reason === 'multiple_worksheets') {
            this.error_message = 'ERR_MULTIPLE_WORKSHEETS';

            this.$nextTick(()=>{ this.error_message = null; });
          }
          else if (result.data.reason === 'too_many_rows') {
            this.error_message = 'ERR_IMPORT_TOO_MANY_ROWS';

            this.$nextTick(()=>{ this.error_message = null; });
          }
        }
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.ERROR'));
      }

      this.$refs['fullscreen-loader-ai'].hide();

      return null;
    },

    async put_import(record) {
      try {
        const result = await axios.put(`/import/${record.import_id}`, record);

        if (result.status === 200) {
          return result.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.NOT_FOUND'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.ERROR'));
      }

      return null;
    },


    async delete_import(import_id) {
      try {
        const result = await axios.delete(`/import/${import_id}`);

        if (result.status === 204) {
          return true;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.UNABLE_DELETE'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.UNABLE_DELETE'));
      }

      return false;
    },

    async get_import(import_id) {
      try {
        const result = await axios.get(`/import/${import_id}`);

        if (result.status === 200) {
          return result.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.NOT_FOUND'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.ERROR'));
      }

      return null;
    },

    async get_active_imports() {
      try {
        const result = await axios.get(`/import/jobs/${this.currentCompanyId}/${this.currentPeriodId}`);

        if (result.status === 200) {

          let items = result.data;

          items = items.filter(item => item.status.indexOf('FINALIZE') === -1);
          items = items.filter(item => item.variant === this.variant);

          return items;
        }

        console.error('get imports error 1', result.status);
      }
      catch (err) {
        console.error('get imports error 2', err);
      }

      return [];
    },

  }
};
</script>
