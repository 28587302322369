<template>
  <div>
    <b-table
      id="imports-table"
      class="mh-100 table-striped"
      :fields="headers"
      :items="items"
      head-variant="light"
      sticky-header
      hover
      sort-icon-left
    >
      <template #cell(status)="data">
        <b-badge :variant="status_variant(data.item.status)">{{ status_text(data.item.status) }}</b-badge>
      </template>
      <template #cell(actions)="data">
        <div class="justify-content-end d-flex">
          <a
            href="#"
            class="btn btn-icon btn-light btn-sm mx-3"
            @click.prevent="select_import_clicked(data.item.import_id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>

          <a
            class="btn btn-icon btn-light btn-sm mx-3"
            @click.prevent="delete_import_clicked(data.item.import_id)"
            >
            <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>

      </template>

    </b-table>

</div>
</template>

<script>
import dayjs from 'dayjs';
import TipLabel from '@/view/components/TipLabel.vue';

export default {

  name: "import-table",

  props: {
    items: []
  },
  emits: ['on_import_selected', 'on_import_deleted'],
  components: {
    TipLabel
  },

  computed: {},

  methods: {
    status_variant(status) {
      switch (status) {
        case 'FAIL_VALIDATION':
        case 'FAIL_FINALIZE':
          return 'danger';

        case 'DONE_VALIDATION':
        case 'DONE_MAP_COLUMNS':
        case 'WAIT_VALIDATION':
        case 'WAIT_FINALIZE':
          return 'warning';

        case 'RUN_FINALIZE':
        case 'RUN_VALIDATION':
          return 'secondary';

        case 'DONE_FINALIZE':
          return 'success';

      }
    },

    status_text(status) {
      return this.$t('PAGES.IMPORT.STATUSES.' + status);
    },

    select_import_clicked(import_id) {
      this.$emit('on_import_selected', import_id);
    },
    delete_import_clicked(import_id) {
      this.$emit('on_import_deleted', import_id);
    }
  },

  data() {
    return {

      headers: [
        {
          key: 'created_at',
          show: true,
          sortable: true,
          label: this.$t('PAGES.IMPORT.CREATED_AT'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'status',
          show: true,
          sortable: false,
          label: this.$t('PAGES.IMPORT.STATUS'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'actions',
          show: true,
          sortable: false,
          label: '',
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        }
      ]
    };
  }
};
</script>
