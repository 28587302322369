<template>
  <div class="mt-8">
    
    <ImportTable 
      :items="historic_imports"
      @on_import_selected="on_import_selected"
      @on_import_deleted="on_import_deleted"
    />

    <ImportEditor
      v-if="import_record"
      :disabled="true"
      :show_tab="editor_show_tab"
      :import_record="import_record"
      :variant="variant"
    />
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import ImportTable from '@/view/pages/ml/import/components/ImportTable.vue';
import ImportEditor from '@/view/pages/ml/import/ImportEditor.vue';
import ImportNewImportModal from '@/view/pages/ml/import/components/ImportNewImportModal.vue';

export default {
  name: 'import-page',
  mixins: [ toasts ],
  components: {
    ImportTable,
    ImportNewImportModal,
    ImportEditor
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'isTHS'])
  },
  
  data() {
    return {
      import_record: null,
      historic_imports: [],
      editor_show_tab: 'options'
    };
  },
  watch: {
   
  },
  props: ['variant'], /* variant = MEMBER, COMPANY */

  async mounted() {
    this.historic_imports = await this.get_historic_imports();
  },

  methods: {
   
    async on_import_selected(import_id) {
      try {
        this.import_record = await this.get_import(import_id);

        this.$nextTick(() => {
          this.editor_show_tab = 'options';
        });
      }
      catch (err) {
      
      }
    },

    async on_import_deleted(import_id) {
      try {
        const result = await this.delete_import(import_id);

        if (result) {
          if (this.import_record && this.import_record.import_id === import_id) {
            this.import_record = null;
          }

          this.historic_imports = this.historic_imports.filter(item => item.import_id !== import_id);
        }
        
      }
      catch (err) {
      
      }
    },

    async delete_import(import_id) {
      try {
        const result = await axios.delete(`/import/${import_id}`);

        if (result.status === 204) {
          return true;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.UNABLE_DELETE'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.UNABLE_DELETE'));
      }

      return false;
    },

    async get_import(import_id) {
      try {
        const result = await axios.get(`/import/${import_id}`);

        if (result.status === 200) {
          return result.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.NOT_FOUND'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.ERROR'));
      }

      return null;
    },

    async get_historic_imports() {
      try {
        const result = await axios.get(`/import/jobs/${this.currentCompanyId}/${this.currentPeriodId}`);

        if (result.status === 200) {

          let items = result.data;

          items = items.filter(item => item.status.indexOf('DONE') !== -1);

          return items;
        }

        console.error('get imports error 1', result.status);
      }
      catch (err) {
        console.error('get imports error 2', err);
      }

      return [];
    }
  }
};
</script>
